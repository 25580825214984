import {styled} from 'styled-components';
import {Route} from 'wouter';

import {Modal} from '@shared-web/components/core/modal';

import {HomePage} from '@src/components/home_page';
import {NavBar} from '@src/components/nav_bar';
import {ThemePage} from '@src/components/theme_page';

export const App: React.FC = () => {
  const routes = (
    <>
      <Route path="/" component={HomePage} />
      <Route path="/theme" component={ThemePage} />
    </>
  );

  return (
    <Route path="/" nest>
      <>
        <Wrapper>
          <NavBar />
          {routes}
        </Wrapper>
        <Modal />
      </>
    </Route>
  );
};
App.displayName = 'App';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;
