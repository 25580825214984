import {APP_API} from '@shared/api/app_api';
import {AllApiSchema} from '@shared/api/core/api_schema';
import {ApiConfig, ApiName} from '@shared/api/core/api_types';
import {APP_BACKEND_URL} from '@shared/env';

export const ALL = {
  app_backend: APP_API,
} satisfies AllApiSchema;

export const API_CONFIGS = {
  app_backend: {host: APP_BACKEND_URL},
} satisfies Record<ApiName, ApiConfig>;
