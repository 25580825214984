import {useCallback} from 'react';
import {styled} from 'styled-components';

import {Button} from '@shared-web/components/core/button';

import {apiCall} from '@src/lib/api';

export const HomePage: React.FC = () => {
  const handleClick = useCallback(async () => {
    const res = await apiCall('POST /list-models', {});
    console.log(JSON.parse(res.data));
  }, []);

  return (
    <Wrapper>
      <div>Home Page</div>
      <Button onClickAsync={handleClick}>{`HELLO`}</Button>
    </Wrapper>
  );
};
HomePage.displayName = 'HomePage';

const Wrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: safe center;
  &:first-child {
    margin-top: auto;
  }
  &:last-child {
    margin-bottom: auto;
  }
`;
