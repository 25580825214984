import {Obj, Str} from '@shared/api/core/api_schema';

export const APP_API = {
  '/list-models': {
    POST: {
      req: Obj({}),
      res: Obj({data: Str()}),
    },
  },
};
